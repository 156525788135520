//Watch and Wager Main Manifest

//Import & Output Normalize
@import "normalize/import-now";

//Import globals
@import "globals/mixins";
@import "globals/variables";

//Import theme
@import "themes/waw";

//Import base
@import "globals/animations";
@import "base/base";
@import "globals/helpers";

//Import components
@import "components/components";

.l-header_placeholder {
  height: $header-height-mobile;

  @media screen and (min-width: $screen-nav-desktop-min) {
    height: $header-height;
  }

  .l-header {
    padding: 10px 0 30px;

    .nav_logo,
    .nav_item--logo {
      display: block;
      height: 41px;
      width: 175px;

      @media screen and (min-width: $screen-nav-desktop-min) {
        display: table-cell;
        height: 75px;
        width: 320px;
      }
    }

    .nav_table--sub {
      top: 69px;
    }

    .nav--sub {
      background-image: -webkit-linear-gradient(#807b7b, #504747);
      background-image: linear-gradient(#807b7b, #504747);
      display: table;
      left: 50%;
      max-width: 1220px;
      padding: 3px 2px;
      position: fixed;
      table-layout: fixed;
      top: 105px;
      transform: translate(-50%, 0);
      width: 100%;

      .nav_table {
        text-align: left;

        @media screen and (min-width: $screen-nav-desktop-min) {
          display: table-cell;
        }

        .nav_item {
          margin-right: 2px;
          padding: 0;

          @media screen and (min-width: $screen-nav-desktop-min) {
            display: inline-block;
          }
        }

        .nav_link {
          background: #201F1D;
          display: block;
          font-size: 12px;
          font-weight: 700;
          margin: 0 1px;
          max-height: 28px;
          padding: 8px 10px;
          text-align: center;

          &:hover {
            background: rgba(0, 0, 0, 0.15);
            border-color: transparent;
            max-height: 30px;
          }

          .nav_link--triangle {
            display: inline-block;
            margin-right: 5px;

            &:after {
              border-style: solid;
              border-width: 6px 0 6px 6px;
              border-color: transparent transparent transparent #ef0001;
              content: "";
              display: inline-block;
              height: 0;
              margin-left: 5px;
              position: relative;
              top: 2px;
              width: 0;
            }
          }
        }
      }

      .nav_table--social-media {
        background: #212123;
        margin-top: 4px;
        padding: 5px 2px;
        text-align: right;

        @media screen and (min-width: $screen-nav-desktop-min) {
          background: transparent;
          margin-top: 0;
          padding: 0;
        }

        .nav_link {
          border-radius: 50%;
          margin: 0 2px;
          max-height: 30px;
          padding: 8px;
          position: relative;
          vertical-align: middle;
          width: 30px;
        }

        .nav_link--facebook {
          background: #3B5998;
        }

        .nav_link--twitter {
          background: #00aced;
        }

        .nav_link--instagram {
          background: #9b6954;
          height: 30px;
          width: 33px;

          .instagram_icon {
            display: block;
            left: 50%;
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 15px;

            path {
              fill: white;
            }
          }
        }

        .nav_item--search {
          .search_form {
            position: relative;
          }

          .form_input {
            background: white;
            border-color: transparent;
            color: #212123;
            font-size: 12px;
            height: 31px;
            margin-left: 8px;
            width: 200px;

            @media screen and (min-width: $screen-nav-desktop-min) {
              background: #212123;
              color: #fff;
            }
          }

          .search_submit {
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translate(0, -50%);

            .search_icon {
              display: block;
              width: 15px;

              path {
                fill: grey;

                @media screen and (min-width: $screen-nav-desktop-min) {
                  fill: white;
                }
              }
            }
          }
        }
      }
    }
  }
}

.l-main {
  .marquee {
    .l-row {
      padding-top: 50px;
    }
  }

  .game-page {
    padding-top: 50px;
  }

  .leaderboard {
    top: 173px;
  }
}

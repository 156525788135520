//Watch and Wager Theme

//Brand Colors
// Set the main theme with these 4 brand color variables.
// Then fine tune the major components below if necessary.
$brand-name: "waw";
$brand-primary-color: #676363;
$brand-dark-primary-color: darken($brand-primary-color, 10%);
$brand-darker-primary-color: darken($brand-primary-color, 20%);
$brand-secondary-color: #ef0001;

//Brand Logos
$brand-logo-color: url(../public/images/logos/#{$brand-name}-logo.svg);
$brand-logo-avatar: url(../public/images/logos/#{$brand-name}-avatar.jpg);
$brand-nav-logo-bkg-values: transparent $brand-logo-color no-repeat center (left / 100%);

//Base Colors
$base-header-bkg-color: $brand-darker-primary-color;
$base-body-bkg-color: $black;
$base-font-color: $white;
$action-color: $brand-secondary-color;
$light-action-color: lighten($action-color, 40%);
$dark-action-color: darken($action-color, 20%);
$base-header-bkg-gradient: url(../public/images/backgrounds/bkg-header-waw.png) $base-header-bkg-color;

//Typography
$heading-font-family: -apple-system, "Helvetica Neue", Helvetica, Arial, sans-serif;
$secondary-font-family: $heading-font-family;
$tertiary-font-family: $heading-font-family;

//Tab Colors
// The Filter component and Accordion component will match
// these settings unless explicitly set below.
$tab-font-color: $white;
$tab-bkg-color: $brand-primary-color;
$tab-active-color: $base-header-bkg-color;
$tab-hover-color: $action-color;
$tab-font-weight: 500;
$tab-font-size: 14px;

//Filter Colors
$filter-font-color: $tab-font-color;
$filter-bkg-color: $tab-bkg-color;
$filter-active-color: $tab-active-color;
$filter-hover-color: $brand-dark-primary-color;

//Accordion Colors
$accordion-font-color: $tab-font-color;
$accordion-bkg-color: $tab-bkg-color;
$accordion-hover-color: $brand-dark-primary-color;
$accordion-close-color: $tab-font-color;

//Game Feed Link Colors
$game-feed-link-active-color: $brand-dark-primary-color;

//Nav Colors
$nav-mobile-link-color: $white;
$nav-desktop-link-color: $white;
$nav-mobile-bkg-color: $brand-primary-color;
$nav-mobile-menu-bkg-color: $brand-primary-color;
$nav-mobile-menu-font-color: $base-font-color;
$nav-mobile-menu-close-color: $white;
$nav-mobile-menu-item-link-color: $white;

//Brand Row Colors
$brand-row-font-color: $white;
$brand-row-bkg-color: $base-header-bkg-gradient;

//Tournament Table Colors
$tournament-table-link-color: $brand-primary-color;
$tournament-table-link-hover-color: $brand-dark-primary-color;

//Game Page Colors
$leaderboard-current-user-row-color: $blue;
$leaderboard-list-bkg-color: $base-header-bkg-color;

// Header Sizes
$header-height-mobile: 215px;
$header-height: 195px;

//Form Colors
$form-box-shadow: inset 0 1px 3px rgba($black, 0.06);
$form-box-shadow-focus: $form-box-shadow, 0 0 5px adjust-color($action-color, $lightness: -5%, $alpha: -0.3);

//Lightbox Colors
$lightbox-bkg-color: rgba($base-body-bkg-color, 0.9);

//Notification Colors
$notice-color: $blue;
$light-notice-color: lighten($copen-blue, 38%);
$caution-color: $dark-yellow;
$light-caution-color: lighten($caution-color, 60%);
$bkg-caution-color: $yellow;
$error-color: #a50013;
$light-error-color: lighten($error-color, 58%);
$dark-error-color: darken($error-color, 10%);
$success-color: $lime;
$light-success-color: lighten($success-color, 55%);
$dark-success-color: darken($success-color, 15%);
